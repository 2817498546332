








































































import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/display/placeholder.js'
import { codemirror } from 'vue-codemirror'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import AddWinnerViewModel from '../viewmodels/add-winner-viewmodel'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

@Observer
@Component({
  components: {
    DropDragableUpload: () => import('@/components/drop-dragable-upload.vue'),
    MenuDownloadSample: () => import('@/components/menu-download-sample.vue'),
    codemirror
  }
})
export default class SenderPrepare extends Vue {
  @Inject() vm!: AddWinnerViewModel
  onCmCodeChange(newCode: string) {
    this.vm.changeState({ codeMirrorText: newCode })
  }
  cmOption = {
    focus: true,
    placeholder: 'Insert address and amount, separate with comma',
    tabSize: 1,
    styleActiveLine: true,
    lineNumbers: true,
    line: true,
    deleting: false,
    singleCursorHeightPerLine: false
  }
  codeMirror: any
  uploaded(res) {
    this.vm.changeIsUploadFile(false)
    this.vm.walletEditor.setValue(res)
    this.codeMirror.refresh()
    this.vm.validatePrepareStep()
  }

  async next() {
    try {
      this.vm.changeNextStepLoading(true)
      const res = await this.vm.prepareNextAction()
      this.vm.changeNextStepLoading(false)
      if (res) await this.vm.setCurrentStep(2)
    } catch (e) {
      snackController.error(e.message || e.msg)
      this.vm.changeNextStepLoading(false)
    }
  }

  mounted() {
    this.codeMirror = this.$refs.codeMirror!
    this.codeMirror.codemirror.on('change', () => {
      if (this.vm.isEmptyError) {
        this.vm.changeEmptyError()
      }
    })
    this.vm.changeState({ walletEditor: (this.codeMirror as any).codemirror.doc })
  }
}
